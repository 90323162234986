body {
  margin: 0;
  padding: 0;
  filter: grayscale(100%);
  font-family: 'Arial', sans-serif;
}

.App {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-left: 10%;
  text-align: left;
  background-color: #f8f9fa;
}

.App-header {
  max-width: 600px;
}

h1 {
  font-size: 2.5rem;
}

.highlight {
  display: block;
  font-size: 5rem; /* Increased size by one step */
  font-weight: 900; /* Keeping it bold for emphasis */
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #b0b0b0;
  font-style: italic;
}

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 1rem;
}

.social-links a {
  color: #333;
  font-size: 2rem;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #007bff;
}

